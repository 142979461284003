.start-menu-container {
  position: absolute;
  z-index: 99;
  left: 0;
  background: $lightColor;
  @include transform(scale(0));
  transform-origin: left top;
  transition: all 0.2s ease-in-out 0s;
  padding: 18.8027rem 0;

  &.active {
    @include transform(scale(1));
    transform-origin: 20% 0;
    transition: all 0.2s ease-in-out 0s;
  }

  .menu-item {
    font-size: 45.1264rem;
    padding: 0 18.8027rem;

    &:hover {
      color: #fff;
      background: $mint;
    }
  }
}