.programWindow {
  position: absolute;
  margin: auto;
  inset: 0px;
  max-width: 2143.5058rem;
  max-height: 1504.2146rem;
  width: 100%;
  height: 100%;
  border-radius: 3.7605rem;
  box-shadow: rgb(0 0 0 / 35%) 13.1619rem 13.1619rem 0px 0px;
  background: #3e3a44;
  transition: max-width 0.3s ease-in-out, max-height 0.3s ease-in-out;

  &.topWindow {
    z-index: 1;
  }

  &.fullScreen {
    max-width: 100%;
    max-height: 100%;

    .programWindowHeader {
      border-top: 1.8803rem solid #ccc;
    }
  }

  .programWindowHeader {
    background: $lightColor;
    position: relative;

    ul {
      display: flex;
      flex: 0 0 auto;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      @media (max-width: 1024px) {
        left: auto;
        right: 0;
      }

      li {
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 41.3659rem;
        padding: 9.4013rem;
        cursor: pointer;

        &:hover {
          svg {
            color: #000;
          }
        }

        &.close {
          background: $salmon;
        }

        &.fullscreen {
          background: $mint;
        }

        svg {
          display: block;
          color: #fff;
          transition: 0.2s;
        }
      }
    }

    .title {
      color: #000;
      font-size: 41.3659rem;
      font-weight: 600;
      text-align: center;
      padding: 9.4013rem 0;
    }
  }

  &.aboutMeWindow {
    background: #fff;
    overflow: hidden;

    .the-content-wrapper {
      height: 100%;
      overflow-y: scroll;
      padding: 28.204rem 272.6389rem;

      .the-content {
        color: #131313;

        b,
        strong {
          font-weight: 600;
        }

        h3 {
          font-size: 48.887rem;
          margin-bottom: 28.204rem;
        }

        h4 {
          font-size: 45.1264rem;
          margin-bottom: 28.204rem;
        }

        p {
          font-size: 41.3659rem;

          &:not(:last-of-type) {
            margin-bottom: 28.204rem;
          }
        }

        a {
          color: #0000ee;
          text-decoration: underline;
        }

        ul {
          li {
            font-size: 41.3659rem;

            &:not(:last-of-type) {
              margin-bottom: 28.204rem;
            }
          }
        }
      }

      .skills {
        align-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 28.204rem 103.4148rem;

        h2 {
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
          font-size: 43.2462rem;
          margin: 37.6054rem 0 75.2107rem 0;
          color: #131313;
          font-weight: 600;
          text-align: center;
        }

        .skill {
          flex: 0 0 25%;
          height: 131.6188rem;
          margin: 0 0 37.6054rem 0;
          max-width: 25%;
          width: 131.6188rem;
        }
      }
    }
  }

  &.projects {
    overflow: hidden;

    .the-content-wrapper {
      padding: 28.204rem;
      overflow-y: auto;

      .projects-gallery-wrapper {
        position: relative;
      }

      .projects-navigation {
        // display: flex;
        flex-wrap: wrap;
        border-top: 1.8803rem solid $lightColor;

        .single-project {
          // flex: 0 0 50%;
          // max-width: 50%;
          // width: 50%;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          justify-content: flex-start;
          align-items: flex-start;

          &.loading {
            cursor: wait;
            position: relative;
            pointer-events: none;

            &::before {
              pointer-events: all;
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              background: rgba(255, 255, 255, 0.5);
              left: 0;
              top: 0;
            }
          }

          // margin-bottom: 30.0843rem;
          padding: 28.204rem;
          border-bottom: 1.8803rem solid $lightColor;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }

          &.active {
            background: rgba(255, 255, 255, 0.3);
          }

          // Every odd project
          &:nth-of-type(odd) {
            border-right: 1.8803rem solid $lightColor;
          }

          &:nth-of-type(even) {
            border-left: 1.8803rem solid $lightColor;
          }

          .img-wrapper {
            width: 188.0268rem;
            height: 188.0268rem;
            flex: 0 0 188.0268rem;
            margin-right: 47.0067rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top;
            }
          }

          .content-wrapper {
            flex: 0 0 calc(100% - 188.0268rem - 47.0067rem);
            width: calc(100% - 188.0268rem - 47.0067rem);
            padding: 0 9.4013rem 9.4013rem 9.4013rem;
            align-self: center;

            h2 {
              font-size: 52.6475rem;
            }
          }
        }
      }
    }

    .single-project-window {
      position: absolute;
      bottom: 0;
      width: 100%;
      right: 0;
      background-color: $dark;
      transition: all 0.3s ease-in-out;
      @include transform(scale(0));

      &.active {
        right: 0;
        @include transform(scale(1));
      }

      .project-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 18.8027rem;
        width: 100%;

        .close-project,
        .btn {
          flex: 0 0 calc(50% - 18.8027rem);
          width: calc(50% - 18.8027rem);
        }

        .btn {
          padding: 30rem 25rem;
        }
      }

      .close-project {
        font-size: 41.1264rem;
        cursor: pointer;
        transition: 0.3s;
        display: flex;
        color: #ef737b;
        align-items: center;
        align-content: center;
        z-index: 999;

        svg {
          margin-left: 18.8027rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .single-project-content {
        height: 100%;
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        align-items: stretch;

        .project-images {
          max-width: 65%;
          flex: 0 0 65%;
          width: 65%;
          height: 100%;
          max-height: 100%;
          position: relative;

          @include for-phone {
            max-width: 100%;
            flex: 0 0 100%;
            width: 100%;
            height: 50%;
          }

          .swiper {
            height: 100%;
            max-height: 100%;
            padding-bottom: 75.2107rem;

            img {
              width: calc(100% - 112.8161rem);
              height: auto;
              cursor: grab;

              &:active {
                cursor: grabbing;
              }
            }

            .swiper-button-next,
            .swiper-button-prev {
              color: #fff;
              background: $mint;

              &::after {
                font-size: 30.0843rem;
                transition: 0.4s;
              }

              &:hover {
                &::after {
                  @include transform(scale(1.2));
                }
              }
            }

            .swiper-button-prev {
              left: 0;
            }

            .swiper-button-next {
              right: 0;
            }

            .swiper-pagination-bullet {
              background: $mint;
              @include borderRadius(0%);
            }
          }
        }

        .content-wrapper {
          flex: 0 0 35%;
          max-width: 35%;
          width: 35%;
          padding: 110.204rem 28.204rem 60.204rem 28.204rem;
          background: #1e1c20;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: flex-start;

          @include for-phone {
            max-width: 100%;
            flex: 0 0 100%;
            width: 100%;
            padding: 110.204rem 28.204rem 60.204rem 28.204rem;
          }

          h2 {
            font-size: 52.6475rem;
            margin-bottom: 28.204rem;
          }

          p {
            font-size: 41.3659rem;

            &:not(:last-of-type) {
              margin-bottom: 28.204rem;
            }
          }

          ul {
            display: flex;
            margin-bottom: auto;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: center;
            margin-top: 47.0067rem;

            li {
              margin-bottom: 18.8027rem;
              font-size: 33.8448rem;
              padding: 9.4013rem;
              border: 1.8803rem solid $mint;

              &:not(:last-of-type) {
                margin-right: 18.8027rem;
              }
            }
          }

          .btn {
            display: block;
            text-align: center;
            margin-left: auto;
          }
        }
      }
    }

    .zoom-controls {
      display: flex;
      gap: 10px;
      justify-content: center;
      margin: 10px 0;
      position: absolute;
      left: 59%;
      z-index: 999;
      bottom: 0;
    }

    .zoom-controls button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #20c0a0;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 8rem;
      cursor: pointer;
      font-size: 22rem;
    }
  }

  &.experience {
    max-height: 752.1073rem;

    &.fullScreen {
      max-height: 100%;
    }

    .experience-wrapper {
      overflow-y: auto;
      height: 100%;

      .tr {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        .th,
        .td {
          text-align: center;
          padding: 18.8027rem;

          &:not(:first-of-type) {
            flex: 0 0 28.3333333%;
            width: 28.3333333%;
          }

          &:first-of-type {
            width: 15%;
          }
        }

        .th {
          font-size: 52.6475rem;
          color: $mint;
          border-bottom: 1.8803rem solid $mint;

          &:not(:last-of-type) {
            border-right: 1.8803rem solid $mint;
          }
        }

        .td {
          border-bottom: 1.8803rem solid $mint;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          font-size: 37.6054rem;

          .img-wrapper {
            width: 100%;
            height: 94.0134rem;
            padding: 9.4013rem;

            img {
              @include fullSizeImg(contain);
            }
          }
        }
      }
    }
  }
}