.btn {
  padding: 18.8027rem 37.6054rem;
  font-size: 41.3659rem;
  color: $mint;
  border: 3.7605rem solid $mint;
  transition: 0.3s;
  display: inline-flex;
  cursor: pointer;
  &:hover {
    background: $mint;
    color: #fff;
  }
  &-blank {
    background: none;
    outline: none;
  }
}

button {
  font-family: $determination;
}
