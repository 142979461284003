.clippy-assistant {
  position: fixed;
  bottom: 120px;
  left: 20px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;

  @media (max-width: 768px) {
    display: none;
  }

  .clippy-image {
    width: 120px;
    height: 120px;
    display: block;
    margin-top: 10px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .speech-bubble {
    background: #fff;
    border: 2px solid #000;
    color: #000;
    border-radius: 10px;
    padding: 15px 35px 15px 15px;
    position: relative;
    max-width: 250px;
    font-size: 18px;
    pointer-events: auto;

    .close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #000;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &::before {
        content: '×';
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 30px;
      bottom: -20px;
      border: 10px solid transparent;
      border-top-color: #fff;
    }

    &:before {
      content: '';
      position: absolute;
      left: 30px;
      bottom: -23px;
      border: 10px solid transparent;
      border-top-color: #000;
    }
  }

  &.closing {
    .clippy-image {
      animation: clippyExit 0.5s ease-in forwards;
    }
    .speech-bubble {
      animation: fadeOut 0.3s ease-out forwards;
    }
  }
}

@keyframes clippyExit {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(0.8) rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
