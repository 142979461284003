#desktop-wrapper {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  #desktopScreen {
    // background: darkblue;
    left: 0px;
    bottom: 0px;
    width: 100%;
    position: fixed;
    padding: 71.4502rem;
    display: flex;
    &.busyCursor {
      cursor: wait;
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
      }
    }
    .desktop-icons-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;
      // width: 30%;
      margin-left: auto;
      flex-wrap: wrap;
      align-content: flex-end;
      justify-content: space-between;
    }
  }
}
@mixin sp-layout {
  @media screen and (max-width: 750px) {
    @content;
  }
}
#desktopBackground {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  pointer-events: none;
  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: -1;
    opacity: 0.5;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
