#preloader {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
  z-index: 999;
  background: rgb(21, 18, 22);
  .text-message {
    font-family: DOS, Courier, "Courier New", monospace;
    color: rgb(189, 189, 198);
    font-size: 41.3659rem;
    line-height: 1.26em;
    p {
      display: block;
      position: relative;
      width: fit-content;
      &:last-of-type {
        &::after {
          content: "";
          position: absolute;
          right: -0.5em;
          @include ver-center();
          height: 41.3659rem;
          width: 5.6408rem;
          background-color: rgb(189, 189, 198);
        }
      }
    }
  }
  &.done {
    .text-message {
      display: none;
    }
    .os-screen {
      display: block;
      width: 100%;
      flex: 0 0 100%;
      height: 100%;
      position: relative;
      z-index: 999;
      .wrapper {
        width: 940.1341rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: absolute;
        @include abs-center();
        .logo-wrapper {
          width: 564.0805rem;
          height: 564.0805rem;
          flex: 0 0 auto;
          img {
            @include fullSizeImg(contain);
          }
        }
        .progress-bar {
          background: #fff;
          height: 56.408rem;
          @include borderRadius(9.4013rem);
          transition: 0.1s;
        }
      }
    }
  }
  &.fully-loaded {
    display: none;
  }
}
