.animate-text,
.animate-text-body {
  transition: 0s;
  * {
    transition: 0s;
    font-weight: inherit;
  }
  .word {
    display: inline-flex;
    overflow: hidden;

    .char {
      display: inline-block;
      transform: translateY(100%);
    }
  }
}
.animate-ltr {
  @include transform(translateX(-100%));
}
// Frames:
