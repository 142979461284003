.contactFormWindow {
  max-width: 100vw;
  max-height: 100vh;
  width: 1200rem;
  height: 1200rem;

  @media (max-width: 1024px) {
    max-width: 100%;
    max-height: 100%;
  }

  .the-content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    form {
      padding: 47.0067rem;

      * {
        font-family: $determination;
      }

      .form-field {
        margin-bottom: 18.8027rem;

        &:not(:first-of-type) {
          margin-top: 37.6054rem;
        }

        label {
          display: block;
          font-size: 41.3659rem;
        }

        input,
        textarea {
          &:not([type="submit"]) {
            width: 100%;
            padding: 9.4013rem 18.8027rem;
            resize: none;
            font-size: 41.3659rem;
            border: 3.7605rem solid $dark;
            background: $lightColor;
          }

          &.error {
            box-shadow: 0 0 0 3.7605rem firebrick;
          }
        }

        input[type="submit"] {
          border: 3.7605rem solid $mint;
          background: $mint;
          color: #fff;
          font-size: 45.1264rem;
          padding: 18.8027rem 37.6054rem;
          cursor: pointer;
          box-shadow: none;
          transition: 0.3s;
          margin-left: auto;
          display: block;

          &:hover {
            background: $dark;
            box-shadow: 0 0 0 3.7605rem $mint;
          }
        }
      }
    }

    .form-status-msg {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 60%;
      padding: 9.4013rem;

      .msg {
        font-size: 41.3659rem;
      }

      &.form-loading {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-size: 122.2174rem;
        color: $mint;

        svg {
          -webkit-animation: rotate-scale-up 2s linear infinite both;
          animation: rotate-scale-up 2s linear infinite both;
        }
      }

      &.form-success {
        background: $mint;
        color: #fff;
      }

      &.form-error {
        background: firebrick;
        color: #fff;
      }
    }
  }
}

@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }

  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }

  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}

@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }

  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }

  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}