.desktop-icon {
  cursor: pointer;
  margin-bottom: 47.0067rem;
  margin-right: 47.0067rem;
  &:not(:last-of-type) {
  }
  .icon-wrapper {
    font-size: 154.182rem;
    margin-bottom: 9.4013rem;
    svg {
      display: block;
      margin: 0 auto;
      width: 122.2174rem;
    }
  }
  p {
    text-align: center;
    font-size: 33.8448rem;
    // max-width: 225.6322rem;
    margin: 0 auto;
  }
  &:hover {
    &:not(.singleClick) {
      outline: 1.8803rem solid $accent;
    }
  }
  &:active,
  &.singleClick {
    background: $accent;
  }
}
