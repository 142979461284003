@font-face {
    font-family: 'Determination Mono Web';
    src: url('DeterminationMonoWeb.eot');
    src: url('DeterminationMonoWeb.eot?#iefix') format('embedded-opentype'),
        url('DeterminationMonoWeb.woff2') format('woff2'),
        url('DeterminationMonoWeb.woff') format('woff'),
        url('DeterminationMonoWeb.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

