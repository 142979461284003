#taskbar {
  display: flex;
  align-items: stretch;
  align-content: center;
  flex-wrap: wrap;
  background: $lightColor;
  color: #000;
  padding: 9.4013rem 28.204rem;
  .start-menu {
    flex: 0 0 94.0134rem;
    height: inherit;
    width: 94.0134rem;
    margin-right: 30.0843rem;
    cursor: pointer;
    .start-menu-button {
      width: 100%;
      height: 100%;
      &:hover {
        background: $accent;
      }
      img {
        @include fullSizeImg(contain);
        display: block;
      }
    }
  }
  .options {
    margin-right: auto;
    ul {
      li {
        color: #000;
      }
    }
  }
  .toolbar {
    .social-media {
      margin-right: 47.0067rem;
      li {
        line-height: 1;
        &:not(:last-of-type) {
          margin-right: 28.204rem;
        }
        a {
          color: #000;
          padding: 3.7605rem;
          display: block;
          font-size: 45.1264rem;
          &:hover {
            color: #fff;
            background: $accent;
          }
          svg {
            display: block;
          }
        }
      }
    }
    p {
      pointer-events: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 41.3659rem;
    }
  }
}
