* {
  font-weight: normal;
}
html {
  font-size: 0.0277vw;
  font-family: $determination;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  @media (max-width: 768px) {
    font-size: 0.0577vw;
  }
  @media (max-width: 480px) {
    font-size: 0.09vw;
  }
}
// ::selection {
//   color: #a67744;
//   background: rgba(255, 255, 255, 0.99);
// }

body {
  font-size: 32rem;
  font: 9pt/1.5em "Determination Mono Web", monospace;
  font-weight: 400;
  line-height: 1.2;
  color: $lightColor;
  background: rgb(21, 18, 22);
  text-align: left;
  margin: 0;
  height: 100vh;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
